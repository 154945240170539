import React, { useState } from "react"
import {
  Card,
  Text,
  Collapsible,
  FormLayout,
  Select,
} from "@shopify/polaris"
import update from "immutability-helper"
export default function ButtonsForm({
  ButtonWrapper,
  dropdownButtonMarkup,
  state,
  setState,
}) {
  const [open, setOpen] = useState(0)
  const handleToggle = (index) => {
    if (open === index) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }
  return (
    <Card>
      <Card.Section>
        <p>
          Buttons
        </p>
      </Card.Section>
      <Card.Section>
        <ButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            handleToggle(0)
          }}
          ariaExpanded={open === 0}
          ariaControls={`primary-button-collapsible`}
        >
          {dropdownButtonMarkup("Primary button", "", open === 0)}
        </ButtonWrapper>
      </Card.Section>
      {open === 0 && (
        <Card.Section>
          <Collapsible
            open={open === 0}
            id={`primary-button-collapsible`}
            transition={{
              duration: "500ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            <Card sectioned subdued>
              <FormLayout>
                <Select
                  label="Background style"
                  options={[
                    { label: "None", value: "NONE" },
                    { label: "Solid", value: "SOLID" },
                  ]}
                  value={state.primaryButtonBackground}
                  onChange={(value) => { setState(update(state, { primaryButtonBackground: { $set: value } })) }}
                />
                <Select
                  label="Block padding"
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "Extra loose", value: "EXTRA_LOOSE" },
                    { label: "Loose", value: "LOOSE" },
                    { label: "Tight", value: "TIGHT" },
                    { label: "Extra tight", value: "EXTRA_TIGHT" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.primaryButtonBlockPadding}
                  onChange={(value) => { setState(update(state, { primaryButtonBlockPadding: { $set: value } })) }}
                />
                <Select
                  label="Border"
                  options={[
                    { label: "Full", value: "FULL" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.primaryButtonBorder}
                  onChange={(value) => { setState(update(state, { primaryButtonBorder: { $set: value } })) }}
                />
                <Select
                  label="Corner radius"
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "Large", value: "LARGE" },
                    { label: "Small", value: "SMALL" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.primaryButtonCornerRadius}
                  onChange={(value) => { setState(update(state, { primaryButtonCornerRadius: { $set: value } })) }}
                />
                <Select
                  label="Inline padding"
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "Extra loose", value: "EXTRA_LOOSE" },
                    { label: "Loose", value: "LOOSE" },
                    { label: "Tight", value: "TIGHT" },
                    { label: "Extra tight", value: "EXTRA_TIGHT" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.primaryButtonInlinePadding}
                  onChange={(value) => { setState(update(state, { primaryButtonInlinePadding: { $set: value } })) }}
                />
              </FormLayout>
            </Card>
          </Collapsible>
        </Card.Section>
      )}
      <Card.Section>
        <ButtonWrapper
          onClick={(e) => {
            e.preventDefault()
            handleToggle(1)
          }}
          ariaExpanded={open === 1}
          ariaControls={`secondary-button-collapsible`}
        >
          {dropdownButtonMarkup("Secondary button", "", open === 1)}
        </ButtonWrapper>
      </Card.Section>
      {open === 1 && (
        <Card.Section>
          <Collapsible
            open={open === 1}
            id={`secondary-button-collapsible`}
            transition={{
              duration: "500ms",
              timingFunction: "ease-in-out",
            }}
            expandOnPrint
          >
            <Card sectioned subdued>
              <FormLayout>
                <Select
                  label="Background style"
                  options={[
                    { label: "None", value: "NONE" },
                    { label: "Solid", value: "SOLID" },
                  ]}
                  value={state.secondaryButtonBackground}
                  onChange={(value) => { setState(update(state, { secondaryButtonBackground: { $set: value } })) }}
                />
                <Select
                  label="Block padding"
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "Extra loose", value: "EXTRA_LOOSE" },
                    { label: "Loose", value: "LOOSE" },
                    { label: "Tight", value: "TIGHT" },
                    { label: "Extra tight", value: "EXTRA_TIGHT" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.secondaryButtonBlockPadding}
                  onChange={(value) => { setState(update(state, { secondaryButtonBlockPadding: { $set: value } })) }}
                />
                <Select
                  label="Border"
                  options={[
                    { label: "Full", value: "FULL" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.secondaryButtonBorder}
                  onChange={(value) => { setState(update(state, { secondaryButtonBorder: { $set: value } })) }}
                />
                <Select
                  label="Corner radius"
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "Large", value: "LARGE" },
                    { label: "Small", value: "SMALL" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.secondaryButtonCornerRadius}
                  onChange={(value) => { setState(update(state, { secondaryButtonCornerRadius: { $set: value } })) }}
                />
                <Select
                  label="Inline padding"
                  options={[
                    { label: "Base", value: "BASE" },
                    { label: "Extra loose", value: "EXTRA_LOOSE" },
                    { label: "Loose", value: "LOOSE" },
                    { label: "Tight", value: "TIGHT" },
                    { label: "Extra tight", value: "EXTRA_TIGHT" },
                    { label: "None", value: "NONE" },
                  ]}
                  value={state.secondaryButtonInlinePadding}
                  onChange={(value) => { setState(update(state, { secondaryButtonInlinePadding: { $set: value } })) }}
                />
              </FormLayout>
            </Card>
          </Collapsible>
        </Card.Section>
      )}
    </Card>
  )
}
